//@author: travis
//@lastmodified: 20201228

import { S25Datefilter } from "../modules/s25-dateformat/s25.datefilter.service";
import { jSith } from "../util/jquery-replacement";
import { S25Const } from "../util/s25-const";
import { S25Util } from "../util/s25-util";
import { ContactService } from "./contact.service";
import { EmailService } from "./email.service";
import { EventService } from "./event.service";
import { LangService } from "./lang.service";
import { PreferenceService } from "./preference.service";
import { UserprefService } from "./userpref.service";

export class EventEmailService {
    public static sendEventChangedEmail(eventId: number) {
        return PreferenceService.getPreferences(["25L_rsrvwiz_email_roles", "config_ev_display"]).then(
            function (prefs) {
                var promises: any[] = [];
                var subject = "";
                var body = "";
                var sender = S25Const.appName;
                var nameProp: "event_title" | "event_name" =
                    prefs && prefs.config_ev_display && prefs.config_ev_display.value === "title"
                        ? "event_title"
                        : "event_name";

                var requestorPref = S25Util.propertyGetParentWithChildValue(
                    prefs,
                    "role_id",
                    S25Const.requestorRole.event,
                );
                var schedulerPref = S25Util.propertyGetParentWithChildValue(
                    prefs,
                    "role_id",
                    S25Const.schedulerRole.event,
                );
                var emailRequestor = requestorPref && requestorPref.active === "Yes";
                var emailScheduler = schedulerPref && schedulerPref.active === "Yes";

                if (emailRequestor || emailScheduler) {
                    return EventService.getEventInclude(eventId).then(function (eventData) {
                        if (eventData) {
                            var name = eventData[nameProp] || eventData.event_name || eventData.event_title || "";
                            if (parseInt(eventData.version_number as string) <= 0) {
                                subject = "Event Created: " + name;
                            } else {
                                subject = "Event Edited: " + name;
                            }
                            var roles = EventService.extractRoles(eventData);

                            if (roles.requestor.contactId === roles.scheduler.contactId) {
                                if (emailScheduler) {
                                    emailRequestor = false;
                                } else if (emailRequestor) {
                                    emailScheduler = false;
                                }
                            }

                            if (emailRequestor) {
                                body = S25Util.propertyGetVal(requestorPref, "email_role_msg");
                                promises.push(
                                    EventEmailService.getEventEmail(eventData, body, true, sender).then(
                                        function (fullBody) {
                                            return EmailService.sendEmail(
                                                subject,
                                                fullBody,
                                                roles.requestor.email,
                                                null,
                                                null,
                                                null,
                                                null,
                                                null,
                                                null,
                                                eventId,
                                                1,
                                            );
                                        },
                                    ),
                                );
                            }

                            if (emailScheduler) {
                                body = S25Util.propertyGetVal(schedulerPref, "email_role_msg");
                                promises.push(
                                    EventEmailService.getEventEmail(eventData, body, true, sender).then(
                                        function (fullBody) {
                                            return EmailService.sendEmail(
                                                subject,
                                                fullBody,
                                                roles.scheduler.email,
                                                null,
                                                null,
                                                null,
                                                null,
                                                null,
                                                null,
                                                eventId,
                                                1,
                                            );
                                        },
                                    ),
                                );
                            }

                            return S25Util.all(promises);
                        }
                    });
                }
            },
        );
    }

    public static getEventEmail(eventIdOrData: any, bodyMsg: string, isIncludeDetails: boolean, sender: String) {
        return S25Util.all({
            eventData: EventService.coerceToEventDataPromise(eventIdOrData),
            lang: LangService.getLang(),
            dateFormat: UserprefService.getS25Dateformat(),
            timeFormat: UserprefService.getS25Timeformat(),
            currentContact: ContactService.getCurrentContactSimple(),
        }).then(function (resp) {
            var itemId = resp.eventData && resp.eventData.event_id;
            sender = sender || S25Util.propertyGet(resp.currentContact, "contact_name") || S25Const.appName;
            return EventEmailService.formEventEmail(
                bodyMsg,
                sender,
                isIncludeDetails,
                resp.eventData,
                itemId,
                resp.dateFormat,
                resp.timeFormat,
                S25Const.baseUrl,
                resp.lang.div.controls["s25-evdetails_email"],
            );
        });
    }

    public static formEventEmail(
        bodyMsg: string,
        sender: String,
        isIncludeDetails: boolean,
        eventData: any,
        itemId: number,
        dateFormat: any,
        timeFormat: any,
        baseUrl: String,
        emailLang: any,
        linkFormat?: any,
    ) {
        // if (sender.includes(",")) {
        //     const tempArr = sender.split(",");
        //     const formatSender = [tempArr[1], tempArr[0]];
        //     sender = formatSender.join(" ");
        // }

        bodyMsg = bodyMsg || ""; //ensure it is at least empty string to avoid "undefined" in email body
        var cellStyle = ' style="padding: 1em 1.5em;"';
        var detailsUrl = S25Util.getEventDetailsUrl(itemId, linkFormat);
        if (bodyMsg) {
            // bodyMsg = bodyMsg.replace(/\n/g, "<br />");
            bodyMsg =
                '<div style="border: 1px solid; border-radius: 3px">' +
                '<div style="color: #333; font-family: Tahoma,Geneva,sans-serif; font-weight: 600; margin: -13px 0 0 1em; background-color: #fff; width: fit-content; padding: 0 5px">' +
                // "Note from " +  ANG-4406 remove 'Note from {sender} header
                // sender +
                "</div>" +
                '<div style="width:100%;">' +
                "<div" +
                cellStyle +
                ">" +
                bodyMsg +
                "</div>" +
                "</div>" +
                "</div>";
        }

        if (isIncludeDetails) {
            var detailsLabelStyle = ' style="padding: 10px 0 0 1em;"';
            var detailsCellStyle = ' style="padding-top: 10px; width: 60%;"';
            var summary = "";
            var weekdays = S25Const.dows;
            var weekdaysFull = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
            var datePatternShort = "M/d";
            var bindingsList = "";

            if (eventData.profile) {
                var profileLen = eventData.profile.length;
                for (var i = 0; i < profileLen; i++) {
                    var profile = eventData.profile[i];
                    if (profile) {
                        summary += "<div>"; //start div
                        if (
                            profile.profile_name &&
                            S25Util.toStr(profile.profile_name).toLowerCase().indexOf("rsrv_") !== 0
                        ) {
                            summary += "<b>" + profile.profile_name + " </b>";
                        }

                        var dateArr: any = [],
                            dateHash: any = {},
                            adhocDateList = "",
                            initStartDt = S25Util.date.parseDropTZ(profile.init_start_dt),
                            initEndDt = S25Util.date.parseDropTZ(profile.init_end_dt);
                        profile.reservation &&
                            jSith.forEach(profile.reservation, function (_, rsrv) {
                                if (profile.reservation.length === 1) {
                                    initStartDt = S25Util.date.parseDropTZ(rsrv.reservation_start_dt);
                                    initEndDt = S25Util.date.parseDropTZ(rsrv.reservation_end_dt);
                                } else if (profile.reservation.length > 1) {
                                    var startDt = S25Util.date.toS25ISODateStr(
                                        S25Util.date.parseDropTZ(rsrv.reservation_start_dt),
                                    );
                                    if (!dateHash[startDt]) {
                                        dateHash[startDt] = true;
                                        dateArr.push(startDt);
                                    }
                                }
                            });

                        // Go from:
                        // Tue Aug 18 2015 11:15:00 GMT-0700 (Pacific Daylight Time)
                        //To:
                        // 'Tue Aug-18-2015'
                        // 'startTime', '11:15 am'
                        // Based on user preference for dt/time format
                        var startDate = S25Datefilter.transform(initStartDt, dateFormat);
                        var startTime = S25Datefilter.transform(initStartDt, timeFormat);

                        var endDate = S25Datefilter.transform(initEndDt, dateFormat);
                        var endTime = S25Datefilter.transform(initEndDt, timeFormat);

                        if (dateArr && dateArr.length) {
                            for (var k = 0; k < dateArr.length; k++) {
                                adhocDateList += ", " + S25Datefilter.transform(dateArr[k], datePatternShort);
                            }
                            if (adhocDateList.indexOf(", ") === 0) {
                                adhocDateList = adhocDateList.substring(2); //chomp off beginning ", "
                            }
                        }

                        summary += "<div>"; //container div
                        if (startDate !== endDate) {
                            summary += startDate + " " + startTime + " - " + endDate + " " + endTime;
                        } else {
                            summary += startDate + " " + startTime + " - " + endTime;
                        }
                        summary += "</div></div>"; //end divs

                        var weekdayList = [],
                            weekdayString = "",
                            untilDt = null,
                            repetitions = null,
                            sDt = null,
                            type = null,
                            increment = null;
                        if (profile.profile_code) {
                            for (var j = 0; j < 7; j++) {
                                if (profile.profile_code.indexOf(weekdays[j]) > -1) {
                                    weekdayList.push(weekdaysFull[j]);
                                }
                            }

                            if (profile.profile_code.match(/\d{8}T\d{6}/) !== null) {
                                // ex: 20140623T012385-0700
                                sDt = profile.profile_code.match(/\d{8}T\d{6}/)[0];
                                sDt = new Date(sDt.substr(0, 4), sDt.substr(4, 2) - 1, sDt.substr(6, 2));
                                untilDt = S25Datefilter.transform(sDt, datePatternShort);
                            } else if (profile.profile_code.match(/\d{4}-\d{2}-\d{2}T/) !== null) {
                                sDt = profile.profile_code.match(/\d{4}-\d{2}-\d{2}T/)[0];
                                sDt = new Date(sDt.substr(0, 4), sDt.substr(5, 2) - 1, sDt.substr(8, 2));
                                untilDt = S25Datefilter.transform(sDt, datePatternShort);
                            } else if (profile.profile_code.match(/#(\d+)/) !== null) {
                                repetitions = profile.profile_code.match(/#(\d+)/)[1]; // #5
                            }

                            if (profile.profile_code.match(/^[a-zA-Z]+(\d+)/)) {
                                type = profile.profile_code.charAt(0);
                                increment = parseInt(profile.profile_code.match(/^[a-zA-Z]+(\d+)/)[1]);
                            }
                        }

                        if (
                            weekdayList.indexOf("Monday") !== -1 &&
                            weekdayList.indexOf("Tuesday") !== -1 &&
                            weekdayList.indexOf("Wednesday") !== -1 &&
                            weekdayList.indexOf("Thursday") !== -1 &&
                            weekdayList.indexOf("Friday") !== -1 &&
                            weekdayList.indexOf("Saturday") === -1 &&
                            weekdayList.indexOf("Sunday") === -1
                        ) {
                            weekdayString = "weekdays ";
                        } else if (
                            weekdayList.indexOf("Monday") === -1 &&
                            weekdayList.indexOf("Tuesday") === -1 &&
                            weekdayList.indexOf("Wednesday") === -1 &&
                            weekdayList.indexOf("Thursday") === -1 &&
                            weekdayList.indexOf("Friday") === -1 &&
                            weekdayList.indexOf("Saturday") !== -1 &&
                            weekdayList.indexOf("Sunday") !== -1
                        ) {
                            weekdayString = "weekends ";
                        } else if (weekdayList.length > 1) {
                            weekdayString = weekdayList.join(", ");
                            var lastComma = weekdayString.lastIndexOf(",");
                            weekdayString =
                                weekdayString.substring(0, lastComma) +
                                " and" +
                                weekdayString.substring(lastComma + 1) +
                                " ";
                        } else if (weekdayList.length === 1) {
                            weekdayString = weekdayList[0] + " ";
                        } else {
                            weekdayString = "";
                        }

                        if (["D", "W", "M"].indexOf(type) > -1) {
                            summary += "Repeats every ";
                            if (increment === 2) {
                                summary += "other ";
                            } else if (increment === 3) {
                                summary += "3rd ";
                            } else if (increment > 2) {
                                summary += increment + "th ";
                            }
                            if (type === "D") {
                                summary += "day ";
                            } else if (type === "W") {
                                summary += "week ";
                                if (weekdayString.length > 0) {
                                    summary += "on " + weekdayString;
                                }
                            } else if (type === "M") {
                                summary += "month ";
                            }
                            if (untilDt !== null) {
                                summary += "through " + untilDt;
                            } else if (repetitions !== null && repetitions.trim() !== "") {
                                summary += "for " + repetitions + " iterations";
                            }
                        } else if (adhocDateList) {
                            summary += emailLang.text.repeats_on + " " + adhocDateList;
                        }

                        var spaces = S25Util.propertyGetAllUnique(profile, "space_name").join(", ");
                        var resources = S25Util.propertyGetAllUnique(profile, "resource_name").join(", ");
                        var headcount = "";
                        if (profile.registered_count && parseInt(profile.registered_count) > 0) {
                            headcount = "registered " + profile.registered_count;
                        } else if (profile.expected_count && parseInt(profile.expected_count) > 0) {
                            headcount = "expected " + profile.expected_count;
                        }

                        if (headcount) {
                            summary += "<div>" + emailLang.text.headcount + ": " + headcount + "</div>";
                        }
                        if (spaces) {
                            summary += "<div>" + emailLang.text.event_spaces + ": " + spaces + "</div>";
                        }
                        if (resources) {
                            summary += "<div>" + emailLang.text.event_resources + ": " + resources + "</div>";
                        }

                        if (profile.binding_reservation) {
                            var bindingsLen = profile.binding_reservation.length;
                            for (var m = 0; m < bindingsLen; m++) {
                                var binding = profile.binding_reservation[m];
                                if (binding && binding.bound_name && binding.bound_event_id) {
                                    var bindingLink = S25Util.getEventDetailsUrl(
                                        String(binding.bound_event_id).trim(),
                                        linkFormat,
                                    );
                                    bindingsList +=
                                        '<div><span style="padding-right: 3px;">Bound to</span>' +
                                        (linkFormat !== "noLink"
                                            ? '<a href="' + bindingLink + '">' + binding.bound_name.trim() + "</a>"
                                            : "<span>" + binding.bound_name.trim() + "</span>") +
                                        "</div>";
                                }
                            }
                        }
                    }
                }

                if (bindingsList) {
                    summary += "<div>" + bindingsList + "</div>";
                }
                if (summary) {
                    summary = "<div>" + summary + "</div>";
                }
            }

            var title = eventData.event_title || eventData.event_name || "";
            var requestorName = "",
                requestorEmail = "",
                schedulerName = "",
                schedulerEmail = "";
            var primaryOrgs: any[] = [],
                secondaryOrgs: any[] = [];
            var registrationUrl = S25Util.propertyGet(eventData, "registration_url");

            if (eventData.role) {
                eventData.role.map(function (obj: any) {
                    if (
                        obj &&
                        parseInt(obj.role_id) === S25Const.requestorRole.event &&
                        obj.contact &&
                        obj.contact.contact_name &&
                        obj.contact.email
                    ) {
                        requestorName = obj.contact.contact_name;
                        requestorEmail = obj.contact.email;
                    } else if (
                        obj &&
                        parseInt(obj.role_id) === S25Const.schedulerRole.event &&
                        obj.contact &&
                        obj.contact.contact_name &&
                        obj.contact.email
                    ) {
                        schedulerName = obj.contact.contact_name;
                        schedulerEmail = obj.contact.email;
                    }
                });
            }

            if (eventData.organization) {
                eventData.organization.map(function (obj: any) {
                    if (obj && obj.organization_name && obj.primary === "T") {
                        primaryOrgs.push(obj.organization_name);
                    } else if (obj && obj.organization_name) {
                        secondaryOrgs.push(obj.organization_name);
                    }
                });
            }

            var details =
                '<table style="width:100%; margin-top: 30px;">' +
                "<tr>" +
                '<td colspan="2">' +
                '<div style="color: #333; font-family: Tahoma,Geneva,sans-serif; font-weight: 600; margin-left: 1em; padding-bottom: 5px">' +
                emailLang.text.event_details +
                "</div>" +
                '<div style="border-bottom: 1px solid"></div>' +
                "</td>" +
                "</tr>";
            //table continues below and is closed below..
            if (title) {
                details +=
                    "<tr>" +
                    "<td" +
                    detailsLabelStyle +
                    "><b>" +
                    emailLang.text.event_title +
                    "</b></td>" +
                    "<td" +
                    detailsCellStyle +
                    ">" +
                    title +
                    "</td>" +
                    "</tr>";
            }
            details +=
                "<tr>" +
                "<td" +
                detailsLabelStyle +
                "><b>" +
                emailLang.text.event_type +
                "</b></td>" +
                "<td" +
                detailsCellStyle +
                ">" +
                eventData.event_type_name +
                "</td>" +
                "</tr>";
            details +=
                "<tr>" +
                "<td" +
                detailsLabelStyle +
                "><b>" +
                emailLang.text.event_reference +
                "</b></td>" +
                "<td" +
                detailsCellStyle +
                ">" +
                eventData.event_locator +
                "</td>" +
                "</tr>";
            details +=
                "<tr>" +
                "<td" +
                detailsLabelStyle +
                "><b>" +
                emailLang.text.event_state +
                "</b></td>" +
                "<td" +
                detailsCellStyle +
                ">" +
                eventData.state_name +
                "</td>" +
                "</tr>";
            details +=
                "<tr>" +
                "<td" +
                detailsLabelStyle +
                "><b>" +
                emailLang.text.primary_org +
                "</b></td>" +
                "<td" +
                detailsCellStyle +
                ">" +
                primaryOrgs.join("; ") +
                "</td>" +
                "</tr>";
            if (summary) {
                details +=
                    "<tr>" +
                    "<td" +
                    detailsLabelStyle +
                    "><b>" +
                    emailLang.text.profile_summary +
                    "</b></td>" +
                    "<td" +
                    detailsCellStyle +
                    ">" +
                    summary +
                    "</td>" +
                    "</tr>";
            }
            if (secondaryOrgs.length > 0) {
                details +=
                    "<tr>" +
                    "<td" +
                    detailsLabelStyle +
                    "><b>" +
                    emailLang.text.other_orgs +
                    "</b></td>" +
                    "<td" +
                    detailsCellStyle +
                    ">" +
                    secondaryOrgs.join("; ") +
                    "</td>" +
                    "</tr>";
            }
            if (requestorName && requestorEmail) {
                details +=
                    "<tr>" +
                    "<td" +
                    detailsLabelStyle +
                    "><b>" +
                    emailLang.text.requestor +
                    "</b></td>" +
                    "<td" +
                    detailsCellStyle +
                    "><a href=mailto:" +
                    requestorEmail +
                    ">" +
                    requestorName +
                    "</a></td>" +
                    "</tr>";
            }
            if (schedulerName && schedulerEmail) {
                details +=
                    "<tr>" +
                    "<td" +
                    detailsLabelStyle +
                    "><b>" +
                    emailLang.text.scheduler +
                    "</b></td>" +
                    "<td" +
                    detailsCellStyle +
                    "><a href=mailto:" +
                    schedulerEmail +
                    ">" +
                    schedulerName +
                    "</a></td>" +
                    "</tr>";
            }
            if (registrationUrl) {
                details +=
                    "<tr>" +
                    "<td" +
                    detailsLabelStyle +
                    "><b>" +
                    emailLang.text.event_registration_url +
                    "</b></td>" +
                    "<td" +
                    detailsCellStyle +
                    ">" +
                    registrationUrl +
                    "</td>" +
                    "</tr>";
            }
            details += "</table>";
            bodyMsg += details;
        }

        bodyMsg =
            '<html><body style="font-family: Tahoma, Arial, sans-serif; line-height: 150%;">' +
            (linkFormat !== "noLink"
                ? '<div style="width:100%; margin-top:40px; margin-bottom: 30px;">' +
                  '<a style="color: #333; text-decoration: none; font-family: Tahoma,Geneva,sans-serif; font-weight: 600;" href="' +
                  detailsUrl +
                  '">' +
                  eventData.event_name +
                  "</a>" +
                  '<a style="font-family: Tahoma,Geneva,sans-serif;font-size: 10px; padding-left: 3px;" href="' +
                  detailsUrl +
                  '">' +
                  emailLang.text.view_25live +
                  "</a>" +
                  "</div>"
                : "") +
            bodyMsg +
            '<br style="clear:both" /><br/></body></html>';
        return bodyMsg;
    }
}
